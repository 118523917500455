import React from "react";
import DashboardCard from "../dashboardCard/dashboardCard";
import Box from "@mui/material/Box";

export default function settings() {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        marginTop={1}
      >
        <DashboardCard title="Users" icon="group" to="/users" />
        <DashboardCard title="Calendar" icon="calendar" to="/calendar" />
        <DashboardCard title="Students" icon="student" to="/students" />
      </Box>
      {/* <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        marginTop={1}
      >
        <DashboardCard title="Calendar" icon="C" to="/calendar" />
        <DashboardCard title="Calendar" icon="C" to="/calendar" />
      </Box> */}
    </>
  );
}
