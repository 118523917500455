import React, { useState, useEffect } from "react";
import ViewHeader from "../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleTable from "../table/table";
import { format } from "date-fns";
import { fi } from "date-fns/locale";

const CreateUser = () => {
  const navigate = useNavigate();
  const useLocationState = useLocation().state?.record;
  const [resetTrigger, setResetTrigger] = useState(false);

  // State
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const [school, setSchool] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [selectedSchool, setSelectedSchool] = useState(useLocationState?.authorisedUserSchoolId || [""]);

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const columnHeaders = ["FromDate", "ToDate", "Reason", "School"];
    const showHeaders = ["FromDate", "ToDate", "Reason", "School"];

    const displayNamesToAccessors = {
      FromDate: "fromDate",
      ToDate: "toDate",
      Reason: "reason",
      School: "school",
    };

    if (authToken) {
      // School
      const associatedSchools = sessionStorage.getItem("associatedSchools");

      apiService
        .getSchool(authToken, undefined, associatedSchools)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              school: item.schoolName,
              id: item.schoolId,
            };
          });
          setSchool(filteredData);

          apiService.getClosedDays(authToken, associatedSchools).then((response) => {
            const filteredData2 = response.data.map((item) => {
              const matchedItem = filteredData.find((i) => i.id === item.calendarSchoolId);
              return {
                id: item.calendarId,
                school: matchedItem?.school ?? "Unknown",
                fromDate: item.calendarFromDate.slice(0, 10),
                toDate: item.calendarToDate.slice(0, 10),
                reason: item.calendarReason,
              };
            });
            setTableData(filteredData2);
          });

          setColumns(
            columnHeaders.map((key) => {
              return {
                header: key,
                accessor: displayNamesToAccessors[key],
                show: showHeaders.includes(key),
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const getClosedDays = async (authToken) => {
    const associatedSchools = sessionStorage.getItem("associatedSchools");
    const columnHeaders = ["FromDate", "ToDate", "Reason", "School"];
    const showHeaders = ["FromDate", "ToDate", "Reason", "School"];

    const displayNamesToAccessors = {
      FromDate: "fromDate",
      ToDate: "toDate",
      Reason: "reason",
      School: "school",
    };

    apiService.getClosedDays(authToken, associatedSchools).then((response) => {
      const filteredData = response.data.map((item) => {
        return {
          id: item.calendarId,
          school: school.find((i) => i.id === item.calendarSchoolId).school,
          fromDate: item.calendarFromDate.slice(0, 10),
          toDate: item.calendarToDate.slice(0, 10),
          reason: item.calendarReason,
        };
      });
      setTableData(filteredData);
    });

    setColumns(
      columnHeaders.map((key) => {
        return {
          header: key,
          accessor: displayNamesToAccessors[key],
          show: showHeaders.includes(key),
        };
      })
    );
  };

  const handleAddDays = async (e) => {
    e.preventDefault();
    const authToken = sessionStorage.getItem("authToken");

    const fDate = format(new Date(fromDate.$d), "yyyy-MM-dd") + "T00:00:00Z";
    const tDate = format(new Date(toDate.$d), "yyyy-MM-dd") + "T00:00:00Z";

    apiService
      .creataClosedDay(authToken, fDate, tDate, selectedSchool, reason)
      .then((response) => {
        getClosedDays(authToken);
        setFromDate(null);
        setToDate(null);
        setReason("");

        setResetTrigger((prevTrigger) => !prevTrigger);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = async (id) => {
    // Handle delete action for the given ID

    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = await apiService.deleteClosedDay(authToken, id);

        // Fetch updated data after deletion
        getClosedDays(authToken);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <Container>
      <ViewHeader screenName={"Calendar"} />
      <Box display="flex" flexDirection="column" component="form" alignItems="center" justifyContent="center" onSubmit={handleAddDays}>
        <Typography style={{ marginTop: "8px" }}>Add Dates Unavailable (Will include Specific days selected above)</Typography>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" marginTop="20px">
          <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center" marginBottom="10px" width="100%">
            <DatePicker
              key={resetTrigger ? "fromDateEmpty" : "fromDateFull"}
              label="From Date"
              variant="outlined"
              margin="normal"
              onChange={(e) => {
                setFromDate(e);
              }}
              sx={{ width: "200px", height: "50px" }}
            />
            <DatePicker key={resetTrigger ? "toDateEmpty" : "toDateFull"} label="To Date" variant="outlined" margin="normal" onChange={(e) => setToDate(e)} sx={{ width: "200px", height: "50px" }} />
            <TextField label="Reason" variant="outlined" margin="normal" value={reason} onChange={(e) => setReason(e.target.value)} sx={{ width: "200px" }} />
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="row" alignItems="center" marginBottom="10px" width="100%">
            {school.length > 0 && (
              <Select
                value={selectedSchool}
                defaultValue={"Select School"}
                onChange={(e) => {
                  setSelectedSchool(e.target.value);
                }}
                label="School"
                sx={{ width: "200px" }}
              >
                <MenuItem value="" style={{ display: "none" }}>
                  Select School
                </MenuItem>
                {school.map((i) => (
                  <MenuItem key={i?.id} value={i?.id}>
                    {i?.school}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          <Button variant="contained" color="primary" type="submit" sx={{ margin: "10px" }}>
            Add to Calendar
          </Button>
        </Box>
      </Box>
      <hr style={{ margin: "20px 0" }} /> {/* Horizontal line */}
      <SimpleTable
        data={tableData}
        columns={columns}
        // onEdit={handleEdit}
        onDelete={handleDelete}
        page="calendar"
      />
    </Container>
  );
};

export default CreateUser;
