import React, {useState} from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import apiService from "../../../utils/api";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const CreateCategory = () => {

    const navigate = useNavigate();
    const useLocationState = useLocation().state?.record;
    const [category, setCategory] = useState(useLocationState?.category || "");
    const [description, setDescription] = useState(useLocationState?.categoryDescription || "");
    const { id } = useParams();

    const handleCreateCategory = async (e) => {
        e.preventDefault();

        try {
            const authToken = sessionStorage.getItem("authToken");

            if (authToken) {
                const response = useLocationState ? await apiService.updateCategory(authToken, category, description, 1, id) : await apiService.createCatergory(authToken, category, description, 1);
                navigate("/categories")
            }
            else {
                navigate("/");
            }
          } catch (error) {
            console.error("Error Signing In:", error);
          }
    }

  return (
    <Container>
      <ViewHeader screenName={"Create Category"} />
      <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        component={"form"}
        className="custom-box"
        onSubmit={handleCreateCategory}
      >
        <TextField
          label="Category"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          autoFocus
          disabled={useLocationState ? true : false}
          onChange={(e) => setCategory(e.target.value)}
          defaultValue={useLocationState ? useLocationState.category : ""}
        />
        <Typography gutterBottom style={{ marginTop: '8px' }}>
        Add a Description
      </Typography>
        <TextField
          label="Description"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          defaultValue={useLocationState ? useLocationState.categoryDescription : ""}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth type="submit">
          {useLocationState ? "Update" : "Add"}
        </Button>
      </Box>
      </Container>
    </Container>
  );
}

export default CreateCategory
