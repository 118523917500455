import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api.mjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FormControl, InputLabel, Tooltip, IconButton, Snackbar, Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { format } from "date-fns";

const CreateOrders = () => {
  const navigate = useNavigate();
  const useLocationState = useLocation().state?.record;

  // State
  const [student, setStudent] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [county, setCounty] = useState("");
  const [school, setSchool] = useState(useLocationState ? useLocationState.orderSchool : "");
  const [schoolId, setSchoolId] = useState("");
  const [sClass, setSClass] = useState(useLocationState ? useLocationState?.orderStudent?.studentClass.classId : "");
  const [orderDate, setOrderDate] = useState(useLocationState ? useLocationState?.orderDate : "");
  const [allergens, setAllergens] = useState(useLocationState ? useLocationState.allergens : "");
  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState([]);
  const [foodItem, setFoodItem] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [isMenuloaded, setIsMenuloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Start with loading state active
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" }); // Snackbar state

  const { id } = useParams();
  const [selectedMenu, setSelectedMenu] = useState(useLocationState ? useLocationState?.orderMenu?.menuId : [""]);
  const [selectedStudent, setSelectedStudent] = useState(useLocationState ? useLocationState.orderStudent.studentId : [""]);
  const [selectedFoodItem, setSelectedFoodItem] = useState(useLocationState ? useLocationState.orderFoodItem : [""]);
  const [selectedUserProfiles, setSelectedUserProfiles] = useState(useLocationState ? useLocationState.orderStudent.user : [""]);

  const [menuFiltered, setMenuFiltered] = useState(false);

  // Calculate whether to disable food item selection based on the current date
  const isPastOrderDate = useLocationState && dayjs().isAfter(dayjs(orderDate));

  // Effect to fetch data and initialize the component state
  useEffect(() => {
    const fetchData = async () => {
      const authToken = sessionStorage.getItem("authToken");
      const associatedSchools = sessionStorage.getItem("associatedSchools");

      if (!authToken) {
        navigate("/");
        return;
      }

      try {
        // Fetch user profiles and students concurrently
        const [profileResponse, studentResponse] = await Promise.all([
          apiService.getUserProfiles(authToken, associatedSchools),
          apiService.getStudents(authToken),
        ]);

        const profiles = profileResponse.data;
        setUserProfile(profiles);

        const students = studentResponse.data.map((item) => ({
          student: item.studentName,
          class: item.studentClass,
          id: item.studentId,
          parent: item.user,
          school: item.school,
          allergens: item.allergens,
        }));
        setStudent(students);

        if (useLocationState) {
          await handleSelectStudent(useLocationState.orderStudent.studentId, "useLocationState");

          const foodItems = useLocationState.orderFoodItem.map((item) => item.foodItemId);
          setSelectedFoodItem(foodItems);

          if (isMenuloaded) {
            await handleSelectMenu(useLocationState.orderMenu.menuId, "useLocationState");
          }
        }

        // Ensure filtering happens after students are set
        if (selectedUserProfiles) {
          const filteredStudents = students.filter(student => student.parent === selectedUserProfiles);
          setFilteredStudent(filteredStudents);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbar({ open: true, message: "Error fetching data", severity: "error" });
      } finally {
        setIsLoading(false); // Data fetching is done, remove loading state
      }
    };

    fetchData();
  }, [navigate, isMenuloaded, selectedUserProfiles, useLocationState, id]);

  const handleCreateOrder = async (e) => {
    e.preventDefault();

    let oDate = orderDate;
    if (oDate.$d) {
      oDate = format(new Date(oDate.$d), "yyyy-MM-dd") + "T00:00:00Z";
    }

    try {
      const authToken = sessionStorage.getItem("authToken");
      const studentN = student.filter((i) => i.id === selectedStudent)[0].student;
      const parentId = student.filter((i) => i.id === selectedStudent)[0].parent;

      if (authToken) {
        const response = useLocationState
          ? await apiService.updateOrder(authToken, id, selectedStudent, studentN, oDate, parentId, selectedMenu, 1, schoolId, [selectedFoodItem])
          : await apiService.createOrder(authToken, selectedStudent, studentN, oDate, parentId, selectedMenu, 1, schoolId, [selectedFoodItem]);
        setSnackbar({ open: true, message: useLocationState ? "Order updated successfully" : "Order created successfully", severity: "success" });
        // setTimeout(() => navigate("/orders"), 1000); // Delay navigation to show the snackbar message
      } else {
        navigate("/");
      }
    } catch (error) {
      setSnackbar({ open: true, message: "Error saving order", severity: "error" });
      console.error("Error Signing In:", error);
    }
  };

  const handleSelectStudent = async (e, source = "btn") => {
    let nSelectedStudent = "";
    if (source === "btn") {
      nSelectedStudent = e.target.value;
    } else {
      nSelectedStudent = e;
    }

    setSelectedStudent(nSelectedStudent);

    const Students = student.filter((i) => {
      return i.parent === selectedUserProfiles;
    });
    setFilteredStudent(Students);

    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      // Schools
      let id = 0;
      if (student && student.length > 0) {
        id = student.find((i) => i.id === nSelectedStudent)?.school || 0;
      }
      try {
        const schoolResponse = await apiService.getSchool(authToken, id);
        setSchool(schoolResponse.data[0].schoolName);
        setSchoolId(schoolResponse.data[0].schoolId);

        const countyResponse = await apiService.getCounty(authToken, schoolResponse.data[0].schoolCountry);
        setCounty(countyResponse.data[0].countyName);

        const sClass = student.find((i) => i.id === nSelectedStudent)?.class || "";
        const classResponse = await apiService.getClass(authToken, sClass);
        setSClass(classResponse.data[0].className);

        const menuResponse = await apiService.getMenus(authToken, schoolResponse.data[0].schoolId);
        const filteredData = menuResponse.data.map((item) => ({
          menu: item.menuName,
          menuCategory: item.menuCategory,
          menuFoodItems: item.menuFoodItem,
          menuStartDate: item.menuFromDate,
          menuEndDate: item.menuToDate,
          id: item.menuId,
        }));
        setMenu(filteredData);
        setIsMenuloaded(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSelectMenu = async (e, source = "btn") => {
    let nMenu = "";
    if (source === "btn") {
      nMenu = e.target.value;
    } else {
      nMenu = e;
    }

    setSelectedMenu(nMenu);

    const menuCategories = menu?.find((i) => i.id === nMenu)?.menuCategory;
    const menuFoods = menu?.find((i) => i.id === nMenu)?.menuFoodItems;

    const authToken = sessionStorage.getItem("authToken");

    if (authToken && menuFoods) {
      try {
        // Fetch categories
        const categoriesPromises = menuCategories.map(async (categoryId) => {
          const response = await apiService.getCategories(authToken, categoryId);
          return {
            category: response.data[0]?.category,
            id: response.data[0]?.categoryId,
          };
        });
        const categories = await Promise.all(categoriesPromises);
        setCategories(categories);

        // Fetch food items
        const foodItemsPromises = menuFoods.map(async (foodItemId) => {
          const response = await apiService.getFoodItems(authToken, foodItemId);
          return {
            foodItem: response.data[0]?.foodItemName,
            id: response.data[0]?.foodItemId,
            allergens: response.data[0]?.foodItemAllergens,  // Retrieve allergens for each food item
          };
        });
        const foodItems = await Promise.all(foodItemsPromises);

        // Filter food items based on student's allergens
        const studentAllergens = student.find(i => i.id === selectedStudent)?.allergens || [];
        const filteredFoodItems = foodItems.filter(foodItem => {
          return !foodItem.allergens.some(allergen => studentAllergens.includes(allergen));
        });

        setFoodItem(filteredFoodItems); // Update the state with the filtered food items

      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOrderDateChange = (e) => {
    const oDate = e;

    const fMenu = menu.filter((i) => {
      const sDate = new Date(i.menuStartDate);
      const eDate = new Date(i.menuEndDate);
      return oDate >= sDate && oDate <= eDate;
    });
    setMenu(fMenu);
    setMenuFiltered(true);
    setOrderDate(oDate);
  };

  const handleSelectParent = (e) => {
    const nSelectedUserProfiles = e.target.value;
    setSelectedUserProfiles(nSelectedUserProfiles);
    const parent = userProfile.find((i) => i.user === nSelectedUserProfiles);

    const Students = student.filter((i) => i.parent === parent.user);
    setFilteredStudent(Students);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container>
      {isLoading ? (
        <div>Loading...</div> // Show a loading indicator while data is being fetched
      ) : (
        <>
          <ViewHeader screenName={useLocationState ? "Edit Order" : "Create Order"} />
          <Container maxWidth="m">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbar.open}
              autoHideDuration={4000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
              </Alert>
            </Snackbar>
            <Box display="flex" flexDirection="column" component={"form"} className="custom-box" onSubmit={handleCreateOrder}>
              {/* Render parent and student selectors */}
              <Box display="flex" justifyContent="space-between" flexDirection="row" alignContent="center">
                {userProfile.length > 0 && (
                  <FormControl>
                    <InputLabel id="parent">Parent</InputLabel>
                    <Select
                      value={selectedUserProfiles}
                      onChange={handleSelectParent}
                      label="Parent"
                      labelId="parent"
                      sx={{ marginRight: 2, width: "250px" }}
                      disabled={!!useLocationState} // Disable when in edit mode
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        Select Parent
                      </MenuItem>
                      {userProfile.map((i) => (
                        <MenuItem key={i.user} value={i.user}>
                          {i.firstName + " " + i.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {Array.isArray(selectedUserProfiles) ? (
                  ""
                ) : (
                  <FormControl>
                    <InputLabel id="student">Student</InputLabel>
                    <Select
                      value={filteredStudent.some(student => student.id === selectedStudent) ? selectedStudent : ""}
                      onChange={(e) => handleSelectStudent(e)}
                      label="Student"
                      labelId="student"
                      sx={{ marginRight: 2, width: "250px" }}
                      disabled={!!useLocationState} // Disable when in edit mode
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        Select Student
                      </MenuItem>
                      {filteredStudent.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.student}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {Array.isArray(selectedStudent) ? (
                  ""
                ) : (
                  <Box display="flex" justifyContent="space-around" flexDirection="row" alignContent="center">
                    <Typography gutterBottom>County: {county}</Typography>
                    <Typography gutterBottom ml={2}>
                      School: {school}
                    </Typography>
                    <Typography gutterBottom ml={2}>
                      Class: {sClass}
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Order date and menu selection */}
              <Box display="flex" justifyContent="space-between" flexDirection="column" alignContent="center">
                <Typography gutterBottom style={{ margin: "16px" }}>
                  Enter Order Date
                </Typography>
                <Box display="flex" justifyContent="space-between" flexDirection="row">
                  <DatePicker
                    label="Order Date"
                    variant="outlined"
                    margin="normal"
                    disabled={useLocationState ? true : false}
                    defaultValue={useLocationState ? dayjs(useLocationState.orderDate.substr(0, 10)) : ""}
                    onChange={handleOrderDateChange}
                    sx={{ width: "200px" }}
                  />
                </Box>
                {menuFiltered && (
                  <>
                    <Typography gutterBottom style={{ margin: "16px" }}>
                      Select Menu
                    </Typography>

                    <Box display="flex" justifyContent="flex-start" flexDirection="row">
                      <FormControl>
                        <InputLabel id="menu">Menu</InputLabel>
                        <Select
                          value={selectedMenu}
                          defaultValue={"Select Category"}
                          onChange={(e) => {
                            handleSelectMenu(e);
                          }}
                          disabled={useLocationState ? true : false}
                          labelId="menu"
                          label="Menu"
                          sx={{ marginRight: 2, width: "200px" }}
                        >
                          <MenuItem value="" style={{ display: "none" }}>
                            Select Menu
                          </MenuItem>
                          {menu.map((i) => (
                            <MenuItem key={i.id} value={i.id}>
                              {i.menu}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
                {categories.length > 0 && (
                  <Typography gutterBottom style={{ margin: "16px" }}>
                    Order
                  </Typography>
                )}
                {categories.map((i) => (
                  <Box display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center" key={i.id}>
                    <Tooltip title="Only food items that do not contain the student's allergens are shown." arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography gutterBottom style={{ marginTop: "8px" }}>
                      Category: {i.category}
                    </Typography>
                    <FormControl sx={{ marginLeft: 2 }}>
                      <InputLabel id="foodItem">Food Item</InputLabel>
                      <Select
                        value={selectedFoodItem}
                        defaultValue={"Select Food Item"}
                        onChange={(e) => {
                          setSelectedFoodItem(e.target.value);
                        }}
                        label="Food Item"
                        labelId="foodItem"
                        sx={{ width: "200px" }}
                        disabled={isPastOrderDate} // Disable if current date is past order date
                      >
                        <MenuItem value="" style={{ display: "none" }}>
                          Select Food Items
                        </MenuItem>
                        {foodItem.map((i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {i.foodItem}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
              </Box>

              <Box display="flex" justifyContent="space-between" flexDirection="row" mt={2}>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                  {useLocationState ? "Delete" : "Cancel"}
                </Button>

                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </Container>
  );
};

export default CreateOrders;
