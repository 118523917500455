import React from 'react';
import { Card, Paper, Typography } from '@mui/material';

const SummaryCard = ({menuItem, noOrders}) => {


  return (
    <Paper sx={{ p: 1, textAlign: 'center',  width: 120, marginRight: 2}}>
      <Typography variant="body1" component="div" gutterBottom
       style={menuItem === 'Total Orders' ? {color: "#058240"} : {}}>
        {menuItem}
      </Typography>
      <Typography variant="body1" color="textSecondary"
      style={menuItem === 'Total Orders' ? {color: "#058240"} : {}}>
        {noOrders}
      </Typography>
    </Paper>
  );
    };

export default SummaryCard;
