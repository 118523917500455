import React, { useEffect } from "react";
import SimpleTable from "../../table/table";
import { Container, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewHeader from "../../viewHeader/viewHeader";
import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";

export default function Categories() {
  // State
  const [tableData, setTableData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");
    const tableColumns = [
      "menuName",
      "menuDescription",
      "menuPhoto",
      "menuSchool",
      "menuStore",
      "menuCategory",
      "menuFoodItem",
      "menuFromDate",
      "menuToDate",
      "menuOrderDeadlineDate",
      "menuId",
      "order_count",
    ];
    const columnHeaders = [
      "Menu Name",
      "Menu Description",
      "Menu Photo",
      "Menu School",
      "Menu Store",
      "Menu category",
      "Menu Food Item",
      "Menu From Date",
      "Menu To Date",
      "Menu Order Deadline Date",
      "Menu Id",
    ];
    const showHeaders = ["Menu Name", "Menu Description", "Menu School", "Menu From Date", "Menu To Date"];

    if (authToken) {
      setLoading(true); // Start loading
      apiService
        .getMenus(authToken, undefined, associatedSchools)
        .then((response) => {
          apiService
            .getSchool(authToken)
            .then((resp) => {
              let schoolData = resp.data;
              // Create the data state object by mapping the desired keys
              const filteredData = response.data.map((item) => {
                const filteredItem = {};
                tableColumns.forEach((key) => {
                  if (key === "menuId") {
                    filteredItem["id"] = item[key];
                  } else if (key.includes("Date")) {
                    filteredItem[key] = item[key] ? item[key].slice(0, 10) : "";
                  } else if (key === "menuSchool") {
                    filteredItem[key] = schoolData.find((school) => school.schoolId === item[key]).schoolName;
                    filteredItem["schoolId"] = item[key];
                  } else {
                    filteredItem[key] = item[key];
                  }
                });
                return filteredItem;
              });
              if (Object.entries(filterValues).length > 0) {
                let filteredData2 = filteredData.filter((item) => {
                  if (filterValues.fromDate && filterValues.toDateFilter && item.menuFromDate >= filterValues.fromDate && item.menuToDate <= filterValues.toDate) {
                    return true;
                  } else if (filterValues.fromDate && !filterValues.toDate && item.menuFromDate >= filterValues.fromDate) {
                    return true;
                  } else if (!filterValues.fromDate && filterValues.toDate && item.menuToDate <= filterValues.toDate) {
                    return true;
                  } else {
                    return false;
                  }
                });

                setTableData(filteredData2);
              } else {
                setTableData(filteredData);
              }

              setColumns(
                columnHeaders.map((key) => {
                  return {
                    header: key,
                    accessor: key.replace(/\s+/g, "").replace(/(\b\w)/g, (match) => match.toLowerCase()),
                    show: showHeaders.includes(key),
                  };
                })
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setLoading(false); // Stop loading
            });
        })
        .catch((error) => {
          console.error(error);
          setLoading(false); // Stop loading in case of an error
        });
    } else {
      navigate("/");
    }
  }, [navigate, filterValues]);

  const handleUpdateFilters = (filters) => {
    setFilterValues(filters);
  };

  const handleEdit = (id) => {
    // Handle edit action for the given ID
    navigate(`/menus/edit/${id}`, { state: { record: tableData.find((item) => item.id === id) } });
  };

  const handleDelete = async (id) => {
    setLoading(true); // Start loading
    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = await apiService.deleteMenu(authToken, id);

        // Fetch updated data after deletion
        const updatedData = await apiService.getMenus(authToken);
        setTableData(updatedData.data);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      <ViewHeader screenName="Menus" />
      <FiltersHeader add="Add a Menu" onFilterUpdate={handleUpdateFilters} filters={filterValues} />
      
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <SimpleTable data={tableData} columns={columns} onEdit={handleEdit} onDelete={handleDelete} />
      )}
    </Container>
  );
}
