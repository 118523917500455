import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

const ViewHeader = ({screenName}) => {

    const theme = useTheme();
    const navigate = useNavigate();

    const handleGoBack = (screenName) => {
      switch (screenName) {
        case "Menus":
          navigate("/dashboard");
          break;
        case "Users":
          navigate("/settings");
          break;
        case "Orders":
          navigate("/dashboard");
          break;
        case("Food Items"):
          navigate("/dashboard");
          break;
        case("Categories"):
          navigate("/dashboard");
          break;
        case("Allergens"):
          navigate("/dashboard");
          break;
        case("Calendar"):
          navigate("/settings");
          break;
        default:
          navigate(-1);
          break;
      }
    }

    const typographyStyle = {
      color: theme.palette.primary.main, // Use the primary color from the theme
      // Add more styling properties as needed
    };

  return (
    <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton onClick={() => handleGoBack(screenName)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" style={typographyStyle}>{screenName}</Typography>
          <IconButton onClick={() => handleGoBack(screenName)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </Box>
  )
}

export default ViewHeader
