import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { styled } from '@mui/material/styles';

// Define a styled TableCell for the header
const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FED349',
  padding: '8px', // Adjust the padding to make the cells more compact
  fontSize: '14px', // Adjust the font size for the cells

}));

const StyledTableCell = styled(TableCell)({
  padding: '8px', // Adjust the padding to make the cells more compact
  fontSize: '14px', // Adjust the font size for the cells
  overflow: 'hidden', // Handle overflow
  textOverflow: 'ellipsis', // Add ellipsis for overflow
  whiteSpace: 'nowrap', // Prevent line wrapping
  maxWidth: '150px', // Limit cell width
});

const SimpleTable = ({ data, columns, onEdit, onDelete, onCheckboxChange, showCheckboxes = false, page}) => {
  const handleSelectAllChange = (event) => {
    const selectedAll = event.target.checked;
    const updatedData = data.map((row) => ({ ...row, selected: selectedAll }));
    onCheckboxChange(event, null, updatedData);
  };
  
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {showCheckboxes && <StyledHeaderTableCell >
              <Checkbox
                  indeterminate={data.some((row) => row.selected) && !data.every((row) => row.selected)}
                  checked={data.every((row) => row.selected)}
                  onChange={handleSelectAllChange}
                  style ={{
                    color: "#fff",
                  }}
                />
                </StyledHeaderTableCell >}
            {columns.map((column) => (
              column.show ? (
                <StyledHeaderTableCell  key={column.header}>{column.header}</StyledHeaderTableCell >
              ) : null
            ))}
            <StyledHeaderTableCell >Actions</StyledHeaderTableCell >
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              {showCheckboxes && (
                <StyledTableCell>
                  <Checkbox
                    checked={row.selected}
                    onChange={(event) => onCheckboxChange(event, row.id)}
                  />
                </StyledTableCell>
              )}
              {columns.map((column) => (
                column.show ? (
                  <StyledTableCell key={column.accessor}>
                    {row[column.accessor]}
                  </StyledTableCell>
                ) : null
              ))}
              <StyledTableCell>
                {page === 'calendar' ? <Button onClick={() => onDelete(row.id)}>Delete</Button> : null}
                {page ? null : <Button onClick={() => onEdit(row.id)}>{row?.order_count > 0 ? "View" : "Edit"}</Button>}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
