import React, {useState} from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import apiService from "../../../utils/api";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const CreateAllergen = () => {

    const navigate = useNavigate();
    const useLocationState = useLocation().state?.record;
    const [allergen, setAllergen] = useState(useLocationState?.allergenName || "");
    const [description, setDescription] = useState(useLocationState?.allergenDescription || "");
    const { id } = useParams();

    const handleCreateallergen = async (e) => {
        e.preventDefault();

        try {
            const authToken = sessionStorage.getItem("authToken");

            if (authToken) {
                const response = useLocationState ? await apiService.updateAllergen(authToken, allergen, description, id) : await apiService.createAllergen(authToken, allergen, description);
                navigate("/allergens")
            }
            else {
                navigate("/");
            }
          } catch (error) {
            console.error("Error Signing In:", error);
          }
    }

  return (
    <Container>
      <ViewHeader screenName={"Create Allergen"} />
      <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        component={"form"}
        className="custom-box"
        onSubmit={handleCreateallergen}
      >
        <TextField
          label="Allergen"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          autoFocus
          disabled={useLocationState ? true : false}
          onChange={(e) => setAllergen(e.target.value)}
          defaultValue={useLocationState ? useLocationState.allergenName : ""}
        />
        <Typography gutterBottom style={{ marginTop: '8px' }}>
        Add a Description
      </Typography>
        <TextField
          label="Description"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          defaultValue={useLocationState ? useLocationState.allergenDescription : ""}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth type="submit">
          {useLocationState ? "Update" : "Add"}
        </Button>
      </Box>
      </Container>
    </Container>
  );
}

export default CreateAllergen
