import React, { useEffect } from "react";
import SimpleTable from "../../table/table";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewHeader from "../../viewHeader/viewHeader";
import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";

export default function Categories() {
  // State
  const [tableData, setTableData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const tableColumns = ["category", "categoryDescription", "categoryId"];
    const columnHeaders = ["Category", "Category Description", "Category ID"];

    if (authToken) {
      apiService
        .getCategories(authToken)
        .then((response) => {

          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            const filteredItem = {};
            tableColumns.forEach((key) => {
              if (key === "categoryId") {
                filteredItem["id"] = item[key];
              } else {
              filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
          setTableData(filteredData);

          setColumns(
            columnHeaders.map((key) => {
              return {
                header: key,
                accessor: key.replace(/\s+/g, '').replace(/(\b\w)/g, (match) => match.toLowerCase()),
                show: !key.includes("ID")
              };
            })
          );

        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleEdit = (id) => {
    // Handle edit action for the given ID
    navigate(`/categories/edit/${id}`, { state: { record: tableData.find((item) => item.id === id)}});
  };

  const handleDelete = async (id) => {
    // Handle delete action for the given ID
    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
          const response = await apiService.deleteCategory(authToken, id);

          // Fetch updated data after deletion
          const updatedData = await apiService.getCategories(authToken);
          setTableData(updatedData.data);
      }
  } catch (error) {
      console.error("Error deleting:", error);
  }
  };

  return (
    <Container>
      <ViewHeader screenName="Categories" />
      <FiltersHeader add="Add a Category" />
      <SimpleTable
        data={tableData}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </Container>
  );
}
