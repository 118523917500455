import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import apiService from "../../utils/api";

const PrintOrders = () => {
  const location = useLocation();
  const orders = location.state.selectedIds || [];
  const [orderDetails, setOrderDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const authToken = sessionStorage.getItem("authToken");
      if (authToken) {
        const updatedOrders = await Promise.all(
          orders.map(async (order) => {
            const classPromise = apiService.getClass(authToken);
            const allergyPromise = apiService.getAllergens(authToken);

            const responses = await Promise.all([classPromise, allergyPromise]);
            const classesResponse = responses[0];
            const allergyResponse = responses[1];

            //const orderClass = classesResponse.data.find((classItem) => classItem.classId === order.orderStudent.studentClass).className;
            const allergens = order.orderFoodItem[0].foodItemAllergens.map((allergenId) => allergyResponse.data.find((allergen) => allergen.allergenId === allergenId).allergenName);

            const filteredItem = {};
            filteredItem["class"] = order.orderStudent.studentClass.className;
            filteredItem["studentName"] = order.orderStudent.studentName;
            filteredItem["foodItemName"] = order.orderFoodItem[0].foodItemName;
            filteredItem["orderDate"] = order.orderDate.slice(0, 10);
            filteredItem["allergens"] = allergens;
            return filteredItem;
          })
        );

        setOrderDetails(updatedOrders);
      }
    };

    fetchData();
  }, [orders]);

  return (
    <Box p={0}>
      {orderDetails.map((order, index) => (
        <Paper key={index} style={{ width: "5.08cm", height: "2.54cm", display: "flex", flexDirection: "column" }}>
          <Box display="flex" flexDirection="column" style={{ marginTop: "6px", marginLeft: "20px"}}>
          <Typography variant="printLabelHeader">{order.class}</Typography>
          <Typography variant="printLabel" >{order.studentName}</Typography>
          <Typography variant="printLabel">{order.foodItemName}</Typography>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="printLabel">Contains Allergens: <span style={{fontWeight: 600}}>{order.allergens.join(', ')}</span></Typography>
          </div>
          <Typography variant="printLabel">Order Date: {order.orderDate}</Typography>
          </Box>
        </Paper>
      ))}
    </Box>
  );
  
            }

export default PrintOrders;
