import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import WidgetsIcon from "@mui/icons-material/Widgets";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate, useLocation } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Add this import


import { Link } from "react-router-dom";

export default function Navbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    // Remove authToken from session storage and redirect to login
    sessionStorage.removeItem("authToken");
    navigate("/"); // Redirect to your login route
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense">
          {location.pathname !== "/" &&  location.pathname !== "/reset-password" ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          )  : null }
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Lunchbox Larry
          </Typography>
          {sessionStorage.getItem("authToken") && location.pathname !== "/" &&  location.pathname !== "/reset-password" && (
            <IconButton color="inherit" onClick={handleLogout}>
              <ExitToAppIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
        <List>
          {/* Dashboard */}
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {/* Sublist: Menus, Orders, Food Items, Categories, Allergens */}
          <ListItem button component={Link} to="/menus">
            <ListItemIcon>
            <LocalDiningIcon />
            </ListItemIcon>
            <ListItemText primary="Menus" />
          </ListItem>
          <ListItem button component={Link} to="/orders">
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
          <ListItem button component={Link} to="/fooditems">
            <ListItemIcon>
            <SoupKitchenIcon />
            </ListItemIcon>
            <ListItemText primary="Food Items" />
          </ListItem>
          <ListItem button component={Link} to="/categories">
            <ListItemIcon>
            <WidgetsIcon />
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </ListItem>
          <ListItem button component={Link} to="/allergens">
            <ListItemIcon>
            <BubbleChartIcon />
            </ListItemIcon>
            <ListItemText primary="Allergens" />
          </ListItem>
          <Divider />
          {/* Settings */}
          <ListItem button component={Link} to="/settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          {/* Sublist: Users, Calendar */}
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
            <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component={Link} to="/calendar">
            <ListItemIcon>
            <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItem>
          <ListItem button component={Link} to="/students">
            <ListItemIcon>
            <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Students" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
