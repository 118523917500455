import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api.mjs";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const CreateUser = () => {
  const navigate = useNavigate();
  const useLocationState = useLocation().state?.record;

  // State
  const [county, setCounty] = useState([]);
  const [school, setSchool] = useState([]);
  const [firstName, setFirstName] = useState(useLocationState?.authorisedUserFirstName || "");
  const [lastName, setLastName] = useState(useLocationState?.authorisedUserLastName || "");
  const [email, setEmail] = useState(useLocationState?.authorisedUserEmail || "");
  const [phoneNo, setPhoneNo] = useState(useLocationState?.authorisedUserPhoneNo || "");

  // Params
  const { id } = useParams();

  // Selects
  const [selectedCounty, setSelectedCounty] = useState(useLocationState?.authorisedUserCountyId || [""]);
  const [selectedSchool, setSelectedSchool] = useState(useLocationState?.authorisedUserSchoolId || [""]);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");
    if (authToken) {
      // County
      apiService
        .getCounty(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              county: item.countyName,
              id: item.countyId,
            };
          });
          setCounty(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
      // School
      apiService
        .getSchool(authToken, undefined, associatedSchools)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              school: item.schoolName,
              id: item.schoolId,
            };
          });
          setSchool(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleCreateUser = async (e) => {
    e.preventDefault();

    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = useLocationState
          ? await apiService.updateUser(authToken, firstName, lastName, email, phoneNo, selectedCounty, selectedSchool, id)
          : await apiService.createUser(authToken, firstName, lastName, email, phoneNo, selectedCounty, selectedSchool);
        setOpen(true);
        if (useLocationState) {
          navigate("/users");
        } else {
          setSelectedCounty([""]);
          setSelectedSchool([""]);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhoneNo("");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error Signing In:", error);
    }
  };

  return (
    <Container>
      <ViewHeader screenName={"Create User"} />
      <Container maxWidth="m">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
        >
          <Alert severity="success">User Created!</Alert>
        </Snackbar>
        <Box display="flex" flexDirection="column" component={"form"} className="custom-box" onSubmit={handleCreateUser}>
          <Box display="flex" justifyContent="space-around" flexDirection="row">
            <Select value={selectedCounty} onChange={(e) => setSelectedCounty(e.target.value)} label="County" sx={{ marginRight: 2, width: "200px" }}>
              <MenuItem value="" style={{ display: "none" }}>
                Select County
              </MenuItem>
              {county.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.county}
                </MenuItem>
              ))}
            </Select>
            <Select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)} label="School" sx={{ marginRight: 2, width: "200px" }}>
              <MenuItem value="" style={{ display: "none" }}>
                Select School
              </MenuItem>
              {school.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.school}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            flexDirection="row"
            mb={2}
            mt={2} // Add margin bottom to the row
          >
            <TextField id="firstName" label="First Name" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ marginRight: 2 }} />
            <TextField id="lastName" label="Last Name" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ marginRight: 2 }} />
            <TextField id="email" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginRight: 2 }} />
            <TextField id="phoneNo" label="Phone No" variant="outlined" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
          </Box>
          <Button variant="contained" color="primary" fullWidth type="submit">
            {useLocationState ? "Update" : "Add"}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default CreateUser;
