import "./App.css";
import SignIn from "./components/signIn/signIn.js";
import Navbar from "./components/navbar/navbar.js";
import Dashboard from "./components/dashboard/dashboard.js";
import Allergens from "./components/allergens/listAllergens/allergens";
import CreateAllergen from "./components/allergens/createAllergens/createAllergens";
import Categories from "./components/categories/listCategory/categories";
import CreateCategory from "./components/categories/createCategory/createCategory";
import Fooditems from "./components/fooditems/listFoodItems/fooditems";
import CreateFooditems from "./components/fooditems/createFoodItems/createFoodItems";
import Menu from "./components/menus/listMenus/menu";
import CreateMenu from "./components/menus/createMenus/createMenu";
import Orders from "./components/orders/listOrders/orders";
import CreateOrders from "./components/orders/createOrders/createOrders";
import Settings from "./components/settings/settings";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Users from "./components/users/listUsers/users";
import CreateUser from "./components/users/createUsers/createUser";
import Calendar from "./components/calendar/calendar";
import PrintOrders from "./components/print-orders/printOrders";
import ShowNavBar from "./components/hoc/showNavBar";
import ResetPassword from "./components/resetPassword/resetPassword";
import Students from "./components/students/listStudents/students";
import StudentEdit from "./components/students/editStudent/studentEdit";
// import { Navigate } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', Helvetica",
    h5: {
      fontFamily: "'Poppins', Helvetica",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
    },
    printLabel: {
      fontFamily: "'Roboto', Helvetica",
      fontWeight: 400,
      fontSize: 11, // Adjust the font size as needed
      lineHeight: '11px', // Adjust the line height as needed
    },
    printLabelHeader: {
      fontFamily: "'Roboto', Helvetica",
      fontWeight: 600,
      fontSize: 14, // Adjust the font size as needed
      lineHeight: '20px', // Adjust the line height as needed
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: "'Roboto', Helvetica",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: '0.5px',
      },
      body2: {
        fontFamily: "'Roboto', Helvetica",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0px',
      },
    },
  },
  palette: {
    primary: {
      main: '#058240', // Use your primary color
    },
    secondary: {
      main: '#00ff00', // Use your secondary color
    },
  },
});


function App() {
  // const authToken = sessionStorage.getItem("authToken");
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>      
      <Router>
      <ShowNavBar>
          <Navbar />
        </ShowNavBar>
        <Routes>
        <Route path="/" element={<HomeRedirect />} />
          <Route path="/dashboard" element={<HomeRedirect />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/create-category" element={<CreateCategory />} />
          <Route path="/categories/edit/:id" element={<CreateCategory />} />
          <Route path="/allergens" element={<Allergens />} />
          <Route path="/allergens/create-allergen" element={<CreateAllergen />} />
          <Route path="/allergens/edit/:id" element={<CreateAllergen />} />
          <Route path="/fooditems" element={<Fooditems />} />
          <Route path="/fooditems/create-fooditem" element={<CreateFooditems />} />
          <Route path="/fooditems/edit/:id" element={<CreateFooditems />} />
          <Route path="/menus" element={<Menu />} />
          <Route path="/menus/create-menu" element={<CreateMenu />} />
          <Route path="/menus/edit/:id" element={<CreateMenu />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/create-order" element={<CreateOrders />} />
          <Route path="/orders/edit/:id" element={<CreateOrders />} />
          <Route path="/orders/print-orders" element={<PrintOrders />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/create-user" element={<CreateUser />} />
          <Route path="/users/edit/:id" element={<CreateUser />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/students" element={<Students />} />
          <Route path="/students/edit/:id" element={<StudentEdit />} />
        </Routes>
      </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function HomeRedirect() {
  const authToken = sessionStorage.getItem("authToken");
  return authToken ? <Dashboard /> : <SignIn />;
}


export default App;
