import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import ListIcon from "@mui/icons-material/List";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import WidgetsIcon from "@mui/icons-material/Widgets";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from '@mui/icons-material/School';

import "./dashboardCard.css";

const DashboardCard = ({ icon, title, to, iconSize }) => {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "local_dining":
        return <LocalDiningIcon />;
      case "list":
        return <ListIcon />;
      case "soup_kitchen":
        return <SoupKitchenIcon />;
      case "widgets":
        return <WidgetsIcon />;
      case "bubble_chart":
        return <BubbleChartIcon />;
      case "settings":
        return <SettingsIcon />;
      case "group":
        return <GroupIcon />;
      case "calendar":
        return <CalendarMonthIcon />;
      case "student":
        return <SchoolIcon />;
      default:
        return null;
    }
  };
  return (
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }} className="dashboardCard">
      <div>
        <Box>
          <Typography variant="h6" align="center" sx={{ color: "white" }}>
            {title}
          </Typography>
          {icon && (
            <Icon style={{ fontSize: iconSize, color: "white" }}>{renderIcon(icon)}</Icon>
          )}
        </Box>
      </div>
    </Link>
  );
};

export default DashboardCard;
