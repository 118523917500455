import React, { useEffect, useState } from "react";
import { Container, TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Snackbar, Box, Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import apiService from "../../../utils/api";
import ViewHeader from "../../viewHeader/viewHeader";

export default function StudentEdit() {
    const [studentData, setStudentData] = useState({
        studentName: "",
        school: "",
        studentClass: "",
        allergens: [],
    });
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { record } = location.state || {};

    useEffect(() => {
        if (!record) {
            navigate("/students");
            return;
        }
    
        const fetchData = async () => {
            setLoading(true); // Start loading
            const authToken = sessionStorage.getItem("authToken");
            const associatedSchools = sessionStorage.getItem("associatedSchools").split(",").map(Number);
            try {
                const [schoolsResponse, classesResponse, allergensResponse] = await Promise.all([
                    apiService.getSchool(authToken),
                    apiService.getClass(authToken),
                    apiService.getAllergens(authToken),
                ]);
                const filteredSchools = schoolsResponse.data.filter(school => associatedSchools.includes(school.schoolId));
    
                // Set schools and classes
                setSchools(filteredSchools);
                setClasses(classesResponse.data);
                setAllergens(allergensResponse.data);
    
                // Convert allergen names to IDs
                const allergenIds = record.allergens.split(', ').map(allergenName => {
                    const allergen = allergensResponse.data.find(a => a.allergenName === allergenName);
                    return allergen ? allergen.allergenId : null;
                }).filter(id => id !== null);
    
                const studentRecord = {
                    ...record,
                    allergens: allergenIds,
                };
    
                setStudentData(studentRecord);
    
                // Update studentData with default school and class if they exist
                setStudentData(prevData => ({
                    ...prevData,
                    school: schoolsResponse.data.find(school => school.schoolId === record.school)?.schoolId || "",
                    studentClass: classesResponse.data.find(cls => cls.classId === record.studentClass)?.classId || "",
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
                setSnackbar({ open: true, message: "Error fetching data", severity: "error" });
            } finally {
                setLoading(false); // Stop loading
            }
        };
    
        fetchData();
    }, [record, navigate]);

    useEffect(() => {
        if (studentData.school) {
            const schoolClasses = classes.filter(cls => cls.school === studentData.school);
            setFilteredClasses(schoolClasses);
        } else {
            setFilteredClasses([]);
        }
    }, [studentData.school, classes]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setStudentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAllergenChange = (event) => {
        const {
            target: { value },
        } = event;
        setStudentData((prevData) => ({
            ...prevData,
            allergens: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleSave = async () => {
        const authToken = sessionStorage.getItem("authToken");
        setLoading(true); // Start loading
        try {
            await apiService.updateStudent(authToken, studentData.id, studentData);
            setSnackbar({ open: true, message: "Student updated successfully", severity: "success" });
            // setTimeout(() => navigate("/students"), 1000); // Delay navigation to show the snackbar message
        } catch (error) {
            console.error("Error updating student:", error);
            setSnackbar({ open: true, message: "Error updating student", severity: "error" });
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
    <Container>
        <ViewHeader screenName={"Edit Student"} />
        <Container maxWidth="m">
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" component={"form"} className="custom-box">
                    <Box display="flex" justifyContent="space-around" flexDirection="row" mb={2} mt={2}>
                        <TextField
                            id="studentName"
                            name="studentName"
                            label="Student Name"
                            variant="outlined"
                            value={studentData.studentName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-around" flexDirection="row" mb={2} mt={2}>
                        {schools.length > 0 && (
                            <FormControl fullWidth style={{ margin: 10 }}>
                                <InputLabel>School</InputLabel>
                                <Select
                                    label="School"
                                    name="school"
                                    value={studentData.school}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {schools.map((school) => (
                                        <MenuItem key={school.schoolId} value={school.schoolId}>
                                            {school.schoolName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-around" flexDirection="row" mb={2} mt={2}>
                        {filteredClasses.length > 0 && (
                            <FormControl fullWidth style={{ margin: 10 }}>
                                <InputLabel>Class</InputLabel>
                                <Select
                                    label="Class"
                                    name="studentClass"
                                    value={studentData.studentClass}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {filteredClasses.map((cls) => (
                                        <MenuItem key={cls.classId} value={cls.classId}>
                                            {cls.className}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="space-around" flexDirection="row" mb={2} mt={2}>
                        {allergens.length > 0 && (
                            <FormControl fullWidth style={{ margin: 10 }}>
                                <InputLabel>Allergens</InputLabel>
                                <Select
                                    disabled
                                    label="Allergens"
                                    multiple
                                    name="allergens"
                                    value={studentData.allergens}
                                    onChange={handleAllergenChange}
                                    renderValue={(selected) => selected.map(value => {
                                        const allergen = allergens.find(a => a.allergenId === value);
                                        return allergen ? allergen.allergenName : value;
                                    }).join(', ')}
                                    fullWidth
                                >
                                    {allergens.map((allergen) => (
                                        <MenuItem key={allergen.allergenId} value={allergen.allergenId}>
                                            <Checkbox checked={studentData.allergens.indexOf(allergen.allergenId) > -1} />
                                            <ListItemText primary={allergen.allergenName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            )}
        </Container>
    </Container>
);
}
