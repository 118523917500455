import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const ViewHeader = ({
  add = "",
  filters,
  onFilterUpdate,
  selectedIds,
  classes,
  foodItems,
  schools,
  onResetFilter,
  showButtons = true,
}) => {
  const navigate = useNavigate();

  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);
  const [orderDateFilter, setOrderDateFilter] = useState(null);
  const [selectedClass, setSelectedClass] = useState(""); // New state for selected class ID
  const [selectedSchool, setSelectedSchool] = useState(""); // New state for selected school ID
  const [resetTrigger, setResetTrigger] = useState(false);
  const [selectedFoodItems, setSelectedFoodItems] = useState(""); // New state for selected menu ID

  const handleAdd = (screen) => {
    switch (screen) {
      case "Add a Category":
        navigate("/categories/create-category");
        break;
      case "Add an Allergen":
        navigate("/allergens/create-allergen");
        break;
      case "Add a Food Item":
        navigate("/fooditems/create-fooditem");
        break;
      case "Add a Menu":
        navigate("/menus/create-menu");
        break;
      case "Add New Order":
        navigate("/orders/create-order");
        break;
      case "Add a User":
        navigate("/users/create-user");
        break;
      default:
        navigate("/");
        break;
    }
  };

  const handleMenuFilterChange = (filterName, nValue) => {
    let formattedDate = "";

    if (nValue !== null) {
      const dateObject = new Date(nValue.$d);
      const year = dateObject.getUTCFullYear();
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getUTCDate().toString().padStart(2, "0");
      const hours = dateObject.getUTCHours().toString().padStart(2, "0");
      const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");
      const seconds = dateObject.getUTCSeconds().toString().padStart(2, "0");

      formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    } else {
      formattedDate = null;
    }

    const newFilters = { ...filters, [filterName]: formattedDate };
    onFilterUpdate(newFilters);
  };

  const isDstInEffect = (date) => {
    const jan = new Date(date.getFullYear(), 0, 1);
    const jul = new Date(date.getFullYear(), 6, 1);
    const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    return date.getTimezoneOffset() < stdTimezoneOffset;
  };

  const handleOrderFilterChange = (filterName, nValue) => {
    let newFilters = "";
    if (filterName === "orderDate" && nValue !== null) {
      const dateObject = new Date(nValue.$d);
      if (isDstInEffect(dateObject)) {
        dateObject.setHours(dateObject.getHours() + 1);
      }
      const year = dateObject.getUTCFullYear();
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getUTCDate().toString().padStart(2, "0");
      const hours = dateObject.getUTCHours().toString().padStart(2, "0");
      const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");
      const seconds = dateObject.getUTCSeconds().toString().padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
      newFilters = { ...filters, [filterName]: formattedDate };
    } else if (filterName === "selectedSchool") {
      newFilters = { ...filters, [filterName]: nValue };
      setSelectedSchool(nValue);
      setSelectedClass(""); // Reset class when school changes
    } else if (filterName === "selectedClass") {
      newFilters = { ...filters, [filterName]: nValue };
      setSelectedClass(nValue);
    } else if (filterName === "selectedFoodItems") {
      newFilters = { ...filters, [filterName]: nValue };
      setSelectedFoodItems(nValue);
    } else {
      newFilters = { ...filters, [filterName]: nValue };
    }
    onFilterUpdate(newFilters);
    //onResetFilter();
  };

  const handleResetFilters = () => {
    // Clear the filter inputs by resetting state variables
    setFromDateFilter(null);
    setToDateFilter(null);
    setOrderDateFilter(null);
    setSelectedFoodItems("");
    //onResetFilter();

    setResetTrigger((prevTrigger) => !prevTrigger);
    // setResetTrigger1(prevTrigger => !prevTrigger);
    // setResetTrigger2(prevTrigger => !prevTrigger);

    //   handleMenuFilterChange("fromDate", null);
    // handleMenuFilterChange("toDate", null);
    // handleOrderFilterChange("orderDate", null);
    setSelectedClass("");
    setSelectedSchool("");

    // Clear filters in the parent component by passing an empty object
    onFilterUpdate({});
  };

  // Filter classes based on the selected school
  const filteredClasses = selectedSchool
    ? classes.filter((classItem) => classItem.school === parseInt(selectedSchool, 10))
    : [];

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {add.includes("Category") || add.includes("Allergen") || add.includes("Food") ? (
          ""
        ) : (
          <Button variant="contained" startIcon={<ReplayIcon />} onClick={handleResetFilters}>
            Reset Filters
          </Button>
        )}
        {add.includes("Menu") ? (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DatePicker
              key={resetTrigger ? "fromDateEmpty" : "fromDateFull"}
              label="From Date"
              value={fromDateFilter}
              onChange={(newValue) => handleMenuFilterChange("fromDate", newValue)}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              showDaysOutsideCurrentMonth
              sx={{
                marginRight: "10px",
                width: "200px",
                height: "50px",
                padding: "0px",
              }}
            />
            <DatePicker
              key={resetTrigger ? "toDateEmpty" : "toDateFull"}
              label="To Date"
              value={toDateFilter}
              onChange={(newValue) => handleMenuFilterChange("toDate", newValue)}
              sx={{ width: "200px", height: "50px" }}
            />
          </Box>
        ) : (
          ""
        )}
        {add.includes("Add New Order") ? (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DatePicker
              key={resetTrigger ? "orderDateEmpty" : "orderDateFull"}
              label="Order Date"
              value={orderDateFilter}
              onChange={(newValue) => handleOrderFilterChange("orderDate", newValue)}
              sx={{
                marginRight: "10px",
                width: "200px",
                padding: "0px",
              }}
            />
            <Select
              value={selectedSchool}
              onChange={(event) => handleOrderFilterChange("selectedSchool", event.target.value)}
              displayEmpty
              sx={{
                marginRight: "10px",
                width: "170px",
                height: "50px",
                padding: "0px",
              }}
            >
              <MenuItem value="" disabled>
                Select School
              </MenuItem>
              {schools.map((schoolItem) => (
                <MenuItem key={schoolItem.schoolId} value={schoolItem.schoolId}>
                  {schoolItem.schoolName}
                </MenuItem>
              ))}
            </Select>
            {schools.length === 1 || selectedSchool ? (
              <Select
                value={selectedClass}
                onChange={(event) => handleOrderFilterChange("selectedClass", event.target.value)}
                displayEmpty
                sx={{
                  marginRight: "10px",
                  width: "170px",
                  height: "50px",
                  padding: "0px",
                }}
              >
                <MenuItem value="" disabled>
                  Select Class
                </MenuItem>
                {filteredClasses.map((classItem) => (
                  <MenuItem key={classItem.classId} value={classItem.classId}>
                    {classItem.className}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
            <Select
              value={selectedFoodItems}
              onChange={(event) => handleOrderFilterChange("selectedFoodItems", event.target.value)}
              displayEmpty
              sx={{
                marginRight: "10px",
                width: "200px",
                height: "50px",
                padding: "0px",
              }}
            >
              <MenuItem value="" disabled>
                Select Food Item
              </MenuItem>
              {foodItems.map((menuItem) => (
                <MenuItem key={menuItem.foodItemId} value={menuItem.foodItemId}>
                  {menuItem.foodItemName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          ""
        )}
        <Box>
          <Button variant="contained" endIcon={<AddIcon />} onClick={() => handleAdd(add)}>
            {add}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewHeader;
