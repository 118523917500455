import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ShowNavBar = ({children}) => {
    const location = useLocation();
    const [showNavBar, setShowNavBar] = React.useState(true);

    useEffect(() => {
        if (location.pathname === "/orders/print-orders") {
            setShowNavBar(false);
        } else {
            setShowNavBar(true);
        }
    }, [location]);

  return (
    <>{showNavBar && children}</>
  )
}

export default ShowNavBar