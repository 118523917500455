import React, { useEffect } from "react";
import SimpleTable from "../../table/table";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewHeader from "../../viewHeader/viewHeader";
import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";
import SummaryCard from "../../summaryCard/summaryCard";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

export default function Orders() {
  // State
  const [tableData, setTableData] = React.useState([]);
  const [orderData, setOrderData] = React.useState([]); // [orderStudent, orderDate, orderMenu, orderStore, orderSchool, orderFoodItem, orderDate, orderId
  const [columns, setColumns] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const [schools, setSchools] = React.useState([]);
  const [foodItems, setFoodItems] = React.useState([]); // [menuId, menuName, menuDescription, menuDate, menuSchool, menuStore, menuFoodItem, menuId
  const [filterValues, setFilterValues] = React.useState({});
  const [orderItemCounts, setOrderItemCounts] = React.useState([]);
  const [userProfile, setUserProfile] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");

    if (authToken) {
      apiService.getClass(authToken).then((response) => {
        setClasses(response.data);
      });
      apiService.getFoodItems(authToken).then((response) => {
        setFoodItems(response.data);
      });
      apiService.getSchool(authToken, undefined, associatedSchools).then((response) => {
        setSchools(response.data);
      });
      setIsLoading(false);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const fetchAndProcessOrders = () => {
    setIsLoading(true);
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");
    const tableColumns = ["orderDate", "orderStudent", "orderSchool", "orderStore", "orderMenu", "orderFoodItem", "orderId"];
    const columnHeaders = ["Order Date", "Student", "Class", "School", "Menu", "Order"];
    const showHeaders = ["Order Date", "Student", "Class", "School", "Menu", "Order"];

    const displayNamesToAccessors = {
      "Order Date": "orderDate",
      Student: "student",
      School: "orderSchool",
      Order: "foodItems",
      Menu: "menu",
      Class: "class",
    };

    if (authToken) {
      apiService.getClass(authToken).then((response) => {
        setClasses(response.data);
      });

      apiService.getFoodItems(authToken).then((response) => {
        setFoodItems(response.data);
      });
      apiService.getSchool(authToken, undefined, associatedSchools).then((response) => {
        setSchools(response.data);
      });

      const fDate = filterValues.orderDate ? filterValues.orderDate.slice(0, 10) + "T00:00:00.000Z" : new Date().toISOString().slice(0, 10) + "T00:00:00.000Z";
      const fClass = filterValues.selectedClass ? filterValues.selectedClass : 0;
      const fFoodItems = filterValues.selectedFoodItems ? filterValues.selectedFoodItems : 0;
      const fSchool = filterValues.selectedSchool ? filterValues.selectedSchool : 0;

      apiService.getOrdersDetail(authToken, fDate, fClass, fFoodItems, fSchool, associatedSchools).then((response) => {
        const filteredData = response.data.map((item, index) => {
          const filteredItem = {};
          tableColumns.forEach((key) => {
            if (key === "orderMenu") {
              filteredItem["menu"] = item[key]?.menuName;
              filteredItem["menuId"] = item[key]?.menuId;
            } else if (key === "orderStudent") {
              filteredItem["student"] = item[key]?.studentName;
              filteredItem["studentId"] = item[key]?.studentId;
              filteredItem["orderSchool"] = item[key]?.school?.schoolName;
              filteredItem["class"] = item[key]?.studentClass?.className;
              //filteredItem["class"] = classDescription;
              filteredItem["classId"] = item[key]?.studentClass?.classId;
            } else if (key === "orderSchool") {
              //filteredItem["orderSchool"] = schoolDescription;
            } else if (key === "orderId") {
              filteredItem["id"] = item[key];
            } else if (key === "orderFoodItem") {
              filteredItem["foodItems"] = [];
              item[key].forEach((foodItem) => {
                filteredItem["foodItems"].push(foodItem.foodItemName);
              });
              filteredItem["foodItems"] = filteredItem["foodItems"].join(", ");
            } else if (key === "orderDate") {
              filteredItem["orderDate"] = item[key].slice(0, 10);
            } else {
              filteredItem[key] = item[key];
            }
          });
          return filteredItem;
        });

        setTableData(filteredData);

        setOrderData(response.data);

        apiService
          .getOrderItemCount(authToken, fDate, fClass, fFoodItems, fSchool, associatedSchools)
          .then((response) => {
            setOrderItemCounts(response.data);
          })
          .catch((error) => {
            console.error(error);
          });

        setColumns(
          columnHeaders.map((key) => {
            return {
              header: key,
              accessor: displayNamesToAccessors[key],
              show: showHeaders.includes(key),
            };
          })
        );

        setIsLoading(false);
      });
    } else {
      navigate("/");
    }
  };

  const handleSearchClick = () => {
    fetchAndProcessOrders();
  };

  const handleUpdateFilters = (filters) => {
    setFilterValues(filters);
  };

  const handleEdit = (id) => {
    // Handle edit action for the given ID
    navigate(`/orders/edit/${id}`, {
      state: { record: orderData.find((item) => item.orderId === id) },
    });
  };

  const handleDelete = async (id) => {
    // Handle delete action for the given ID
    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = await apiService.deleteFoodItem(authToken, id);

        // Fetch updated data after deletion
        const updatedData = await apiService.getFoodItems(authToken);
        setTableData(updatedData.data);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const handleCheckboxChange = (event, id, updatedData) => {
    if (id === null) {
      if (event.target.checked) {
        const ids = updatedData.map((row) => row.id);
        const orders = ids.map((id) => orderData.find((item) => item.orderId === id));

        setSelectedIds(orders);
      } else {
        setSelectedIds([]);
      }
    } else if (event.target.checked) {
      const order = orderData.find((item) => item.orderId === id);
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, order]);
    } else {
      setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((order) => order.orderId !== id));
    }
  };

  const handlePrintClick = () => {
    navigate("/orders/print-orders", { state: { selectedIds } });
  };

  const resetFilters = () => {
    setSelectedIds([]);
    setTableData([]);
  };

  return (
    <Container>
      <ViewHeader screenName="Orders" />
      <FiltersHeader
        add="Add New Order"
        onFilterUpdate={handleUpdateFilters}
        selectedIds={selectedIds}
        classes={classes}
        schools={schools}
        filters={filterValues}
        foodItems={foodItems}
        onResetFilter={resetFilters}
      />
      <Box
        display={"flex"}
        justifyContent={"center"} // This will center the button horizontally.
        alignItems={"center"} // This will center the button vertically.
        width={1} // This ensures the box takes the full width of its parent.
        height={1} // This ensures the box takes the full height of its parent.
      >
        <Button variant="contained" onClick={handleSearchClick} sx={{ marginRight: 2 }}>
          Get Orders
        </Button>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} flexDirection={"row"} alignItems={"center"}>
        <Button variant="contained" endIcon={<LocalPrintshopIcon />} onClick={handlePrintClick} sx={{ marginRight: 2 }} disabled={selectedIds.length < 1}>
          Print Orders
        </Button>
      </Box>

      {isLoading ? (
        <Box display={"flex"} justifyContent={"space-around"} flexDirection={"row"} alignItems={"center"}>
          <CircularProgress />{" "}
        </Box>
      ) : (
        <>
          <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"} alignItems={"center"} margin={2}>
            {orderItemCounts.map((item) => (
              <SummaryCard key={item.foodItemName} menuItem={item.foodItemName} noOrders={item.order_count} />
            ))}
          </Box>
          <SimpleTable
            data={tableData.map((row) => ({
              ...row,
              selected: selectedIds.some((order) => order.orderId === row.id),
            }))}
            columns={columns}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onCheckboxChange={handleCheckboxChange}
            showCheckboxes={true}
          />
        </>
      )}
    </Container>
  );
}
