import React, { useEffect } from "react";
import SimpleTable from "../../table/table";
import { Container,Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewHeader from "../../viewHeader/viewHeader";
import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";

export default function Categories() {
  // State
  const [tableData, setTableData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const tableColumns = ["foodItemName", "foodItemCategory", "foodItemAllergens", "foodItemIngredients", "foodItemEnergyKj", "foodItemEnergyKcal", "foodItemFat", "foodItemSaturates", "foodItemCarbohydrates", "foodItemSugars", "foodItemFibre", "foodItemProtein", "foodItemSalt", "foodItemComment", "foodItemId"];
    const columnHeaders = ["Food Item", "Category", "Allergens", "Ingredients", "Comment"];
    const showHeaders = ["Food Item", "Category", "Allergens", "Ingredients", "Comment"];

    const displayNamesToAccessors = {
      "Food Item": "foodItemName",
      "Category": "categories",
      "Allergens": "allergens",
      "Ingredients": "foodItemIngredients",
      "Comment": "foodItemComment",
    };

    if (authToken) {
      setLoading(true); // Start loading
      apiService
        .getFoodItems(authToken)
        .then((response) => {
          const getCategoryPromises = response.data.map((item) => apiService.getCategories(authToken));
          const getAllergenPromises = response.data.map((item) => apiService.getAllergens(authToken));
          // Create the data state object by mapping the desired keys

          Promise.all([...getCategoryPromises, ...getAllergenPromises])
          .then((responses) => {
            const categoryResponses = responses.slice(0, getCategoryPromises.length); // Extract category responses
            const allergenResponses = responses.slice(getCategoryPromises.length); // Extract allergen responses
            

          const filteredData = response.data.map((item, index) => {
            const categories = item.foodItemCategory.map((categoryId) =>
              categoryResponses[index].data.find((category) => category.categoryId === categoryId).category
            );

            const allergens = item.foodItemAllergens.map((allergenId) =>
              allergenResponses[index].data.find((allergen) => allergen.allergenId === allergenId).allergenName
            );

            const filteredItem = {};
            tableColumns.forEach((key) => {
              if (key === "foodItemId") {
                filteredItem["id"] = item[key];
              } else if (key === "foodItemCategory") {
                filteredItem["categories"] = categories.join(", ");
                filteredItem[key] = item[key];
              } else if (key === "foodItemAllergens") {
                filteredItem["allergens"] = allergens.join(", ");
                filteredItem[key] = item[key];
              }
              else {
              filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
          setTableData(filteredData);

          setColumns(
            columnHeaders.map((key) => {
              return {
                header: key,
                accessor: displayNamesToAccessors[key],
                show: showHeaders.includes(key)
              };
            })
          );

        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // Stop loading after data processing
        });
      });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleEdit = (id) => {
    // Handle edit action for the given ID
    navigate(`/fooditems/edit/${id}`, { state: { record: tableData.find((item) => item.id === id)}});
  };

  const handleDelete = async (id) => {
    // Handle delete action for the given ID
    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
          const response = await apiService.deleteFoodItem(authToken, id);

          // Fetch updated data after deletion
          const updatedData = await apiService.getFoodItems(authToken);
          setTableData(updatedData.data);
      }
  } catch (error) {
      console.error("Error deleting:", error);
  }
  };

  return (
    <Container>
      <ViewHeader screenName="Food Items" />
      <FiltersHeader add="Add a Food Item" />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <SimpleTable
          data={tableData}
          columns={columns}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Container>
  );
}
