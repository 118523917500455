import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api";
import { useNavigate, useLocation, useParams, Form } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { set } from "date-fns";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const useLocationState = useLocation().state?.record;

  const [error, setError] = useState("");
  // State
  const [foodItem, setFoodItem] = useState(useLocationState?.foodItemName || "");
  const [categories, setCategory] = useState([]);
  const [allergens, setAllergen] = useState([]);
  const [ingredients, setIngredients] = useState(useLocationState?.foodItemIngredients || "");
  const [energKj, setEnergyKj] = useState(useLocationState?.foodItemEnergyKj || "");
  const [energKcal, setEnergyKcal] = useState(useLocationState?.foodItemEnergyKcal || "");
  const [fat, setFat] = useState(useLocationState?.foodItemFat || "");
  const [saturates, setSaturates] = useState(useLocationState?.foodItemSaturates || "");
  const [carbs, setCarbs] = useState(useLocationState?.foodItemCarbs || "");
  const [sugars, setSugars] = useState(useLocationState?.foodItemSugars || "");
  const [fibre, setFibre] = useState(useLocationState?.foodItemFibre || "");
  const [protein, setProtein] = useState(useLocationState?.foodItemProtein || "");
  const [salt, setSalt] = useState(useLocationState?.foodItemSalt || "");
  const [comments, setComments] = useState(useLocationState?.foodItemComment || "");

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategories, setSelectedCategory] = useState(useLocationState?.foodItemCategory || [""]);
  const [selectedAllergens, setSelectedAllergen] = useState(useLocationState?.foodItemAllergens || [""]);

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) {
      apiService
        .getCategories(authToken)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              category: item.category,
              id: item.categoryId,
            };
          });
          setCategory(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
      apiService
        .getAllergens(authToken)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              allergen: item.allergenName,
              id: item.allergenId,
            };
          });
          setAllergen(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCreateFoodItem = async (e) => {
    e.preventDefault();

    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = useLocationState
          ? await apiService.updateFoodItems(
              authToken,
              foodItem,
              selectedCategories,
              selectedAllergens,
              ingredients,
              energKj,
              energKcal,
              fat,
              saturates,
              carbs,
              sugars,
              fibre,
              protein,
              salt,
              comments,
              1,
              id
            )
          : await apiService.createFoodItem(
              authToken,
              foodItem,
              selectedCategories,
              selectedAllergens,
              ingredients,
              energKj,
              energKcal,
              fat,
              saturates,
              carbs,
              sugars,
              fibre,
              protein,
              salt,
              comments,
              1
            );
        navigate("/fooditems/");
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const handleSelectCategory = (value) => {
    if (selectedCategories.includes("")) {
      const updatedCategories = value.filter((item) => item !== "");
      setSelectedCategory(updatedCategories);
    } else if (value.length === 0) {
      setSelectedCategory([""]);
    } else {
      setSelectedCategory(value);
    }
  };

  const handleSelectAllergen = (value) => {
    if (selectedAllergens.includes("")) {
      const updatedCategories = value.filter((item) => item !== "");
      setSelectedAllergen(updatedCategories);
    } else if (value.length === 0) {
      setSelectedAllergen([""]);
    } else {
      setSelectedAllergen(value);
    }
  };

  return (
    <Container>
      <ViewHeader screenName={useLocationState ? "Edit Food Item" : "Create Food Item"} />
      <Container maxWidth="m">
        <Box display="flex" flexDirection="column" component={"form"} className="custom-box" onSubmit={handleCreateFoodItem}>
          {error && (
            <Typography variant="body1" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          <TextField
            label="Food Item"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            autoFocus
            onChange={(e) => setFoodItem(e.target.value)}
            defaultValue={foodItem}
            inputProps={{ maxLength: 20 }}
            disabled={useLocationState}
          />
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <FormControl variant="outlined">
              <InputLabel id="cat-label">Category</InputLabel>
              <Select
                multiple
                value={selectedCategories}
                variant="outlined"
                onChange={(e) => {
                  handleSelectCategory(e.target.value);
                }}
                label="Category"
                labelId="cat-label"
                sx={{ width: "200px" }}
              >
                <MenuItem value="" style={{ display: "none" }}>
                  Select Category
                </MenuItem>
                {categories.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel id="all-label">Allergen</InputLabel>
              <Select
                multiple
                value={selectedAllergens}
                onChange={(e) => handleSelectAllergen(e.target.value)}
                label="Allergen"
                labelId="all-label"
                disabled={useLocationState}
                sx={{ marginRight: 2, width: "200px" }}
              >
                <MenuItem value="" style={{ display: "none" }}>
                  Select Allergen
                </MenuItem>
                {allergens.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.allergen}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Typography gutterBottom style={{ marginTop: "8px" }}>
            Add a Photo
          </Typography>
          <input type="file" accept="image/*" id="upload-photo" style={{ display: "none" }} onChange={handleFileChange} />
          <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
            Upload Photo
          </Button>
          <Typography gutterBottom style={{ marginTop: "8px" }}>
            Add Description & Ingredients
          </Typography>
          <TextField label="Add Ingredients Here" variant="outlined" margin="normal" fullWidth required defaultValue={ingredients} onChange={(e) => setIngredients(e.target.value)} />
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <TextField label="Enegry (Kilojoules)" variant="outlined" margin="normal" defaultValue={energKj} onChange={(e) => setEnergyKj(e.target.value)} />
            <TextField label="Enegry (Kcal)" variant="outlined" margin="normal" defaultValue={energKcal} onChange={(e) => setEnergyKcal(e.target.value)} />
            <TextField label="Fat" variant="outlined" margin="normal" defaultValue={fat} onChange={(e) => setFat(e.target.value)} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <TextField label="Saturdated Fat" variant="outlined" margin="normal" defaultValue={saturates} onChange={(e) => setSaturates(e.target.value)} />
            <TextField label="Carbohydrates" variant="outlined" margin="normal" defaultValue={carbs} onChange={(e) => setCarbs(e.target.value)} />
            <TextField label="Sugars" variant="outlined" margin="normal" defaultValue={sugars} onChange={(e) => setSugars(e.target.value)} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <TextField label="Fibre" variant="outlined" margin="normal" defaultValue={fibre} onChange={(e) => setFibre(e.target.value)} />
            <TextField label="Protein" variant="outlined" margin="normal" defaultValue={protein} onChange={(e) => setProtein(e.target.value)} />
            <TextField label="Salt" variant="outlined" margin="normal" defaultValue={salt} onChange={(e) => setSalt(e.target.value)} />
          </Box>
          <Typography gutterBottom style={{ marginTop: "8px" }}>
            Add Comments
          </Typography>
          <TextField label="Add Comments Here" variant="outlined" margin="normal" fullWidth onChange={(e) => setComments(e.target.value)} defaultValue={comments} />
          <Button variant="contained" color="primary" fullWidth type="submit">
            {useLocationState ? "Update" : "Add"}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default CreateFoodItems;
