import React from "react";
import DashboardCard from "../dashboardCard/dashboardCard";
import Box from "@mui/material/Box";

export default function dashboard() {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        marginTop={1}
      >
        <DashboardCard title="Menus" icon="local_dining" to="/menus"/>
        <DashboardCard title="Orders" icon="list" to="/orders" />
        <DashboardCard title="Food Items" icon="soup_kitchen" to="/fooditems" />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
        marginTop={1}
      >
        <DashboardCard title="Categories" icon="widgets" to="/categories" />
        <DashboardCard title="Allergens" icon="bubble_chart" to="/allergens" />
        <DashboardCard title="Settings" icon="settings" to="/settings" />
      </Box>
    </>
  );
}
