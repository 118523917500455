import React, { useEffect } from "react";
import SimpleTable from "../../table/table";
import { Container, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ViewHeader from "../../viewHeader/viewHeader";
import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";

export default function Users() {
  // State
  const [tableData, setTableData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // Hooks
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");
    const tableColumns = ["authorisedUserFirstName", "authorisedUserLastName", "authorisedUserEmail", "authorisedUserPhoneNo", "authorisedUserCounty", "authorisedUserSchool", "authorisedUserId"];
    const columnHeaders = ["First Name", "Last Name", "Email", "County", "School"];
    const showHeaders = ["First Name", "Last Name", "County", "School", "Email"];

    const displayNamesToAccessors = {
      "First Name": "authorisedUserFirstName",
      "Last Name": "authorisedUserLastName",
      Email: "authorisedUserEmail",
      County: "authorisedUserCounty",
      School: "authorisedUserSchool",
    };

    if (authToken) {
      setLoading(true); // Start loading
      apiService
        .getUsers(authToken, associatedSchools)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            const filteredItem = {};
            tableColumns.forEach((key) => {
              if (key === "authorisedUserCounty") {
                filteredItem["authorisedUserCounty"] = item[key]?.countyName;
                filteredItem["authorisedUserCountyId"] = item[key]?.countyId;
              } else if (key === "authorisedUserSchool") {
                filteredItem["authorisedUserSchool"] = item[key]?.schoolName;
                filteredItem["authorisedUserSchoolId"] = item[key]?.schoolId;
              } else if (key === "authorisedUserId") {
                filteredItem["id"] = item[key];
              } else {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
          setTableData(filteredData);

          setColumns(
            columnHeaders.map((key) => {
              return {
                header: key,
                accessor: displayNamesToAccessors[key],
                show: showHeaders.includes(key),
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleEdit = (id) => {
    // Handle edit action for the given ID
    navigate(`/users/edit/${id}`, { state: { record: tableData.find((item) => item.id === id) } });
  };

  const handleDelete = async (id) => {
     // Handle delete action for the given ID
    setLoading(true); // Start loading
    try {
      const authToken = sessionStorage.getItem("authToken");

      if (authToken) {
        const response = await apiService.deleteCategory(authToken, id);

        // Fetch updated data after deletion
        const updatedData = await apiService.getCategories(authToken);
        setTableData(updatedData.data);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container>
      <ViewHeader screenName="Users" />
      <FiltersHeader add="Add a User" />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <SimpleTable data={tableData} columns={columns} onEdit={handleEdit} onDelete={handleDelete} />
      )}
    </Container>
  );
}
