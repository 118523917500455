import React, { useEffect, useState, useCallback } from "react";
import SimpleTable from "../../table/table";
import { Container, MenuItem, Select, FormControl, InputLabel, Button, Box, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import ViewHeader from "../../viewHeader/viewHeader";
// import FiltersHeader from "../../filtersHeader/filtersHeader";
import apiService from "../../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";

export default function Students() {
    // State
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [newClass, setNewClass] = useState("");
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Hooks
    const navigate = useNavigate();

    // Function to transform student data
    const transformStudentData = (students) => {
        const tableColumns = ["studentName", "studentClass", "school", "user", "allergens", "studentId", "className", "allergenNames", "userName", "schoolName"];
        return students.map((item) => {
            const filteredItem = { selected: false }; // Add selected property
            tableColumns.forEach((key) => {
                if (key === "allergenNames") {
                    filteredItem["allergens"] = item[key].join(", ");
                } else if (key === "studentId") {
                    filteredItem["id"] = item[key];
                } else {
                    filteredItem[key] = item[key];
                }
            });
            return filteredItem;
        });
    };

    // Fetch data
    useEffect(() => {
        const authToken = sessionStorage.getItem("authToken");
        const associatedSchools = sessionStorage.getItem("associatedSchools").split(",").map(Number); // Ensure associatedSchools is an array of numbers
        const columnHeaders = ["Name", "Class", "School", "User", "Allergens"];
        const showHeaders = ["Name", "Class", "School", "User", "Allergens"];

        const displayNamesToAccessors = {
            Name: "studentName",
            Class: "className",
            School: "schoolName",
            User: "userName",
            Allergens: "allergens",
        };

        if (authToken) {
            setLoading(true);
            Promise.all([
                apiService.getStudentsList(authToken, associatedSchools.join(",")),
                apiService.getSchool(authToken),
                apiService.getClass(authToken)
            ])
                .then(([studentsResponse, schoolsResponse, classesResponse]) => {
                    // Process student data
                    const transformedData = transformStudentData(studentsResponse.data);
                    setTableData(transformedData);
                    setFilteredData(transformedData);

                    // Filter schools and classes based on associatedSchools
                    const filteredSchools = schoolsResponse.data.filter(school => associatedSchools.includes(school.schoolId));
                    const filteredClasses = classesResponse.data.filter(cls => associatedSchools.includes(cls.school));

                    // Set schools and classes
                    setSchools(filteredSchools);
                    setClasses(filteredClasses);

                    // Set columns
                    setColumns(
                        columnHeaders.map((key) => {
                            return {
                                header: key,
                                accessor: displayNamesToAccessors[key],
                                show: showHeaders.includes(key),
                            };
                        })
                    );
                })
                .catch((error) => {
                    console.error(error);
                    setSnackbar({ open: true, message: "Error fetching data", severity: "error" });
                })
                .finally(() => {
                    setLoading(false); // Ensure loading state is updated after all operations
                });
        } else {
            navigate("/");
        }
    }, [navigate]);

    const handleEdit = (id) => {
        const student = tableData.find((item) => item.id === id);
        navigate(`/students/edit/${id}`, { state: { record: student } });
    };

    const handleDelete = async (id) => {
        const authToken = sessionStorage.getItem("authToken");
        setLoading(true); // Start loading
        try {
            if (authToken) {
                await apiService.deleteStudent(authToken, id);
    
                // Fetch updated data after deletion
                const updatedData = await apiService.getStudentsList(authToken, sessionStorage.getItem("associatedSchools"));
                const transformedData = transformStudentData(updatedData.data);
                setTableData(transformedData);
                setFilteredData(transformedData);
                setSelectedStudents([]);
                setSnackbar({ open: true, message: "Student deleted successfully", severity: "success" });
            }
        } catch (error) {
            console.error("Error deleting:", error);
            setSnackbar({ open: true, message: "Error deleting student", severity: "error" });
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    const handleBulkDelete = async () => {
        const authToken = sessionStorage.getItem("authToken");
        setLoading(true); // Start loading
        try {
            await Promise.all(selectedStudents.map(studentId => apiService.deleteStudent(authToken, studentId)));
            // Fetch updated data after deletion
            const updatedData = await apiService.getStudentsList(authToken, sessionStorage.getItem("associatedSchools"));
            const transformedData = transformStudentData(updatedData.data);
            setTableData(transformedData);
            setFilteredData(transformedData);
            setSelectedStudents([]);
            setSnackbar({ open: true, message: "Students deleted successfully", severity: "success" });
        } catch (error) {
            console.error("Error deleting students:", error);
            setSnackbar({ open: true, message: "Error deleting students", severity: "error" });
        } finally {
            setLoading(false); // Stop loading
            setDeleteDialogOpen(false); // Close the dialog
        }
    };

    const handleFilterChange = useCallback(() => {
        let filtered = tableData;

        if (selectedSchool) {
            filtered = filtered.filter(student => student.schoolName === selectedSchool);
            setSelectedStudents([]);
        }

        if (selectedClass) {
            filtered = filtered.filter(student => student.className === selectedClass);
            setSelectedStudents([]);
        }

        setFilteredData(filtered);
    }, [selectedSchool, selectedClass, tableData]);

    useEffect(() => {
        handleFilterChange();
    }, [handleFilterChange]);

    const handleCheckboxChange = (event, studentId) => {
        if (studentId === null) {
            // Handling the case when the "Select All" checkbox is clicked
    
            if (event.target.checked) {
                // Create a set of unique school IDs & class names from the filteredData array
                const uniqueSchools = [...new Set(filteredData.map(student => student.school))];
                const uniqueClasses = [...new Set(filteredData.map(student => student.className))];
    
                // Check if there is only one unique school and one unique class
                if (uniqueSchools.length === 1 && uniqueClasses.length === 1) {
                    const studentsFromSameSchoolAndClass = filteredData;
    
                    setSelectedStudents(studentsFromSameSchoolAndClass.map(student => student.id));
                    setFilteredData(studentsFromSameSchoolAndClass.map(student => ({ ...student, selected: true })));
                    setFilteredClasses(classes.filter(cls => cls.school === studentsFromSameSchoolAndClass[0].school));
                } else {
                    setSnackbar({ open: true, message: "Cannot select students from different schools or classes.", severity: "error" });
                    setSelectedStudents([]);
                    setFilteredData(filteredData.map(student => ({ ...student, selected: false })));
                }
            } else {
                // If the "Select All" checkbox is being unchecked (deselected)
                setSelectedStudents([]);
                setFilteredData(filteredData.map(student => ({ ...student, selected: false })));
                setFilteredClasses([]);
            }
        } else { 
            // Handling the case when an individual student's checkbox is clicked
    
            // Find the selected student from the filteredData array
            const selectedStudent = filteredData.find(student => student.id === studentId);
    
            // Check if all currently selected students are from the same school and class as the selected student
            const isSameSchoolAndClass = selectedStudents.every(id => {
                const student = filteredData.find(s => s.id === id);
                return student && student.school === selectedStudent.school && student.className === selectedStudent.className;
            });
    
            if (isSameSchoolAndClass || selectedStudents.length === 0) {
                setSelectedStudents(prevSelected => {
                    // Update the selectedStudents state based on whether the checkbox is checked or unchecked
                    const newSelected = event.target.checked
                        ? [...prevSelected, studentId]  // Add the studentId to the selection
                        : prevSelected.filter(id => id !== studentId);  // Remove the studentId from the selection
    
                    // Mark the student as selected or deselected in the filteredData array
                    setFilteredData(filteredData.map(student =>
                        student.id === studentId ? { ...student, selected: event.target.checked } : student
                    ));
    
                    // Update filteredClasses based on the selected student's school
                    if (event.target.checked) {
                        setFilteredClasses(classes.filter(cls => cls.school === selectedStudent.school));
                    }
    
                    return newSelected;
                });
            } else {
                setSnackbar({ open: true, message: "You can only select students from the same school and class.", severity: "error" });
            }
        }
    };
    

    const handleSchoolChange = (event) => {
        const selectedSchoolName = event.target.value;
        setSelectedSchool(selectedSchoolName);
        setSelectedClass(""); // Reset the selected class when the school changes
        const school = schools.find(school => school.schoolName === selectedSchoolName);
        if (school) {
            setFilteredClasses(classes.filter(cls => cls.school === school.schoolId));
        } else {
            setFilteredClasses([]);
        }
    };

    const handleSwitchClass = async () => {
        if (!newClass) {
            setSnackbar({ open: true, message: "Please select a new class to switch to.", severity: "warning" });
            return;
        }
    
        const authToken = sessionStorage.getItem("authToken");
        setLoading(true); // Start loading
    
        try {
            // Update the selected students' class
            await apiService.updateStudentsClass(authToken, selectedStudents, newClass);
    
            // Fetch updated data after switching class
            const updatedData = await apiService.getStudentsList(authToken, sessionStorage.getItem("associatedSchools"));
            const transformedData = transformStudentData(updatedData.data);
            setTableData(transformedData);
            setFilteredData(transformedData);
            setSelectedClass("");
            setSelectedSchool("");
            setSelectedStudents([]);
            setSnackbar({ open: true, message: "Class switched successfully", severity: "success" });
        } catch (error) {
            console.error("Error switching class:", error);
            setSnackbar({ open: true, message: "Error switching class", severity: "error" });
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    const handleResetFilters = () => {
        setSelectedSchool("");
        setSelectedClass("");
        setSelectedStudents([]);
        setFilteredData(tableData);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container>
            <ViewHeader screenName="Students" />
            {/* <FiltersHeader add="Add a Student" /> */}
            {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <CircularProgress />
            </Box>
        )}
                {!loading && (
            <>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        startIcon={<ReplayIcon />}
                        onClick={handleResetFilters}
                    >
                        Reset Filters
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center" flex="1">
                    <FormControl style={{ margin: 10, minWidth: 200 }}>
                        <InputLabel>School</InputLabel>
                        <Select
                            label="School"
                            value={selectedSchool}
                            onChange={handleSchoolChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {schools.map((school) => (
                                <MenuItem key={school.schoolId} value={school.schoolName}>
                                    {school.schoolName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {schools.length > 1 && (
                        <FormControl style={{ margin: 10, minWidth: 200 }}>
                            <InputLabel>Class</InputLabel>
                            <Select
                                label="Class"
                                value={selectedClass}
                                onChange={(e) => setSelectedClass(e.target.value)}
                                disabled={!selectedSchool} // Disable class dropdown until a school is selected
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {filteredClasses.map((cls) => (
                                    <MenuItem key={cls.classId} value={cls.className}>
                                        {cls.className}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
                <Box display="flex" alignItems="center">
                    {selectedStudents.length > 0 && (
                        <>
                            <FormControl style={{ margin: 10, minWidth: 200 }}>
                                <InputLabel>New Class</InputLabel>
                                <Select
                                    label="New Class"
                                    value={newClass}
                                    onChange={(e) => setNewClass(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {filteredClasses.map((cls) => (
                                        <MenuItem key={cls.classId} value={cls.classId}>
                                            {cls.className}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: 10 }}
                                onClick={handleSwitchClass}
                                disabled={selectedStudents.length === 0}
                            >
                                Switch Class
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                style={{ margin: 10, color: "white" }}
                                onClick={() => setDeleteDialogOpen(true)}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
            <SimpleTable
                data={filteredData}
                columns={columns}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onCheckboxChange={handleCheckboxChange}
                showCheckboxes={true}
            />
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the selected students? All their orders will be deleted, This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleBulkDelete} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar>
            </>
        )}
        </Container>
    );
}
