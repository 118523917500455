import React, { useState } from "react";
import { Container, Typography, Paper, TextField, Button, Box } from "@mui/material";
import apiService from "../../utils/api";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (passwordConfirm !== password) {
      setError("Passwords do not match");
      return;
    }
    
    try {
      const response = await apiService.resetPassword(token, password, email);
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error resetting password:", error.response.data.error);
      setError(error.response.data.error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography mt={2} variant="h4" gutterBottom style={{ textAlign: "center" }}>
        Change Password
      </Typography>
      <Paper elevation={3} sx={{ padding: 2 }}>
        {success ? (
          <Typography variant="body1" style={{ color: "green" }}>
            Password reset successful! You can now login with your new password.
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            {error && <Typography variant="body1" style={{ color: "red" }}>{error}</Typography>}
            <TextField
              type="email"
              label="Email"
              fullWidth
              required
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="text"
              label="Token"
              fullWidth
              required
              margin="dense"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <TextField
              type="password"
              label="New Password"
              fullWidth
              required
              margin="dense"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="Confirm Password"
              fullWidth
              required
              margin="dense"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Reset Password
              </Button>
            </Box>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default ResetPassword;
