import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api.mjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { format } from "date-fns";
import dayjs from "dayjs";
import { all } from "axios";
import { FormControl, InputLabel } from "@mui/material";

const CreateMenu = () => {
  const navigate = useNavigate();
  const useLocationState = useLocation().state?.record;

  // State
  const [error, setError] = useState(null);
  const [menuName, setMenuName] = useState(useLocationState?.menuName || "");
  const [menuDescription, setMenuDescription] = useState(useLocationState?.menuDescription || "");
  // const [menuPhoto, setMenuPhoto] = useState(useLocationState?.menuPhoto || "");
  const [menuFromDate, setMenuFromDate] = useState(useLocationState?.menuFromDate || "");
  const [menuToDate, setMenuToDate] = useState(useLocationState?.menuToDate || "");
  const [menuDeadlineDate, setMenuDeadlineDate] = useState(useLocationState?.menuOrderDeadlineDate || "");
  const [category, setCategory] = useState(useLocationState?.menuCategory || []);
  const [foodItem, setFoodItem] = useState(useLocationState?.menuFoodItem || []);
  const [menuSchool, setMenuSchool] = useState([]);

  const [allergens, setAllergens] = useState([]);

  const { id } = useParams();
  // const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategories, setSelectedCategory] = useState(useLocationState?.menuCategory || [""]);
  const [selectedSchool, setSelectedSchool] = useState(useLocationState?.schoolId || [""]);
  const [selectedFoodItem, setSelectedFoodItem] = useState(useLocationState?.menuFoodItem || [""]);

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");

    if (authToken) {
      apiService
        .getSchool(authToken, undefined, associatedSchools)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              school: item.schoolName,
              id: item.schoolId,
            };
          });
          setMenuSchool(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
      apiService
        .getCategories(authToken)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              category: item.category,
              id: item.categoryId,
            };
          });
          setCategory(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
      apiService
        .getFoodItems(authToken)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              foodItem: item.foodItemName,
              allergens: item.foodItemAllergens,
              id: item.foodItemId,
            };
          });
          setFoodItem(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });

      apiService
        .getAllergens(authToken)
        .then((response) => {
          // Create the data state object by mapping the desired keys
          const filteredData = response.data.map((item) => {
            return {
              allergen: item.allergenName,
              id: item.allergenId,
            };
          });
          setAllergens(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, []);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const handleSelectSchool = (value) => {
    setSelectedSchool(value);
  };

  const handleSelectCategory = (value) => {
    if (selectedCategories.includes("")) {
      const updatedCategories = value.filter((item) => item !== "");
      setSelectedCategory(updatedCategories);
    } else if (value.length === 0) {
      setSelectedCategory([""]);
    } else {
      setSelectedCategory(value);
    }
  };
  const handleSelectFoodItem = (value) => {
    if (selectedFoodItem.includes("")) {
      const updateFoodItems = value.filter((item) => item !== "");
      setSelectedFoodItem(updateFoodItems);
    } else if (value.length === 0) {
      setSelectedFoodItem([""]);
    } else {
      setSelectedFoodItem(value);
    }
  };

  const handleCreateFoodItem = async (e) => {
    e.preventDefault();
    let menuFDate = menuFromDate;
    let menuTDate = menuToDate;
    let menuDDate = menuDeadlineDate;

    if (menuToDate.$d) {
      menuTDate = format(new Date(menuToDate.$d), "yyyy-MM-dd") + "T00:00:00Z";
    }
    if (menuDeadlineDate.$d) {
      menuDDate = format(new Date(menuDeadlineDate.$d), "yyyy-MM-dd") + "T00:00:00Z";
    }
    if (menuFromDate.$d) {
      menuFDate = format(new Date(menuFromDate.$d), "yyyy-MM-dd") + "T00:00:00Z";
    }

    if (menuDDate > menuFDate) {
      setError("Deadline date should be before From Date");
      return;
    } else if (menuFDate > menuTDate) {
      setError("From date should be before To Date");
      return;
    }

    try {
      const authToken = sessionStorage.getItem("authToken");
      const store = sessionStorage.getItem("store");

      if (authToken) {
        const response = useLocationState
          ? useLocationState.order_count > 0
            ? ""
            : await apiService.updateMenu(authToken, id, menuName, menuDescription, selectedSchool, selectedCategories, selectedFoodItem, menuFDate, menuTDate, menuDDate, store)
          : await apiService.createMenu(authToken, menuName, menuDescription, selectedSchool, selectedCategories, selectedFoodItem, menuFromDate, menuToDate, menuDeadlineDate, store);
        navigate("/menus");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error Signing In:", error);
    }
  };

  return (
    <Container>
      <ViewHeader screenName={useLocationState ? "Edit Menu" : "Create Menu"} />
      <Container maxWidth="m">
        <Box display="flex" flexDirection="column" component={"form"} className="custom-box" onSubmit={handleCreateFoodItem}>
          {error && (
            <Typography variant="body1" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
            <TextField
              label="Menu"
              variant="outlined"
              margin="normal"
              required
              disabled={(useLocationState?.order_count ?? 0) > 0}
              fullWidth={false}
              autoFocus
              onChange={(e) => setMenuName(e.target.value)}
              defaultValue={useLocationState ? useLocationState.menuName : ""}
            />
            {menuSchool.length > 0 && (
              <Select
                value={selectedSchool}
                disabled={(useLocationState?.order_count ?? 0) > 0}
                onChange={(e) => {
                  handleSelectSchool(e.target.value);
                }}
                label="School"
                sx={{ marginRight: 2, minWidth: "200px" }}
              >
                <MenuItem value="" style={{ display: "none" }}>
                  Select School
                </MenuItem>
                {menuSchool.map((i) => (
                  <MenuItem key={i?.id} value={i?.id}>
                    {i?.school}
                  </MenuItem>
                ))}
              </Select>
            )}
            {/* </Box> */}
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <Typography gutterBottom style={{ margin: "16px" }}>
              Add Dates available
            </Typography>
            <Typography gutterBottom style={{ marginTop: "16px", paddingLeft: "8px" }}>
              Add a Photo
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <DatePicker
              label="From Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              defaultValue={useLocationState ? dayjs(useLocationState.menuFromDate.substr(0, 10)) : ""}
              format={"DD/MM/YYYY"}
              onChange={(e) => {
                setMenuFromDate(e);
              }}
              sx={{ width: "200px", height: "50px" }}
            />
            <DatePicker
              label="To Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              defaultValue={useLocationState ? dayjs(useLocationState.menuToDate.substr(0, 10)) : ""}
              format={"DD/MM/YYYY"}
              onChange={(e) => setMenuToDate(e)}
              sx={{ width: "200px", height: "50px" }}
            />
            <DatePicker
              label="Deadline Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              defaultValue={useLocationState ? dayjs(useLocationState.menuOrderDeadlineDate.substr(0, 10)) : ""}
              format={"DD/MM/YYYY"}
              onChange={(e) => setMenuDeadlineDate(e)}
              sx={{ width: "200px", height: "50px" }}
            />
            <input
              type="file"
              accept="image/*"
              id="upload-photo"
              style={{ display: "none" }}
              // onChange={handleFileChange}
            />
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
              Upload Photo
            </Button>
          </Box>
          <Typography gutterBottom style={{ margin: "16px", paddingTop: "20px" }}>
            Add Food Items to Menu
          </Typography>
          <Box display="flex" justifyContent="flex-start" flexDirection="row">
            <Box>
              <FormControl>
                <InputLabel id="cat-label">Category</InputLabel>
                <Select
                  multiple
                  value={selectedCategories}
                  disabled={(useLocationState?.order_count ?? 0) > 0}
                  onChange={(e) => {
                    handleSelectCategory(e.target.value);
                  }}
                  label="Category"
                  labelId="cat-label"
                  sx={{ marginRight: 2, width: "200px" }}
                >
                  <MenuItem value="" style={{ display: "none" }}>
                    Select Category
                  </MenuItem>
                  {category.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="fi-label">Food Items</InputLabel>
                <Select
                  multiple
                  value={selectedFoodItem}
                  disabled={(useLocationState?.order_count ?? 0) > 0}
                  onChange={(e) => handleSelectFoodItem(e.target.value)}
                  label="Food Item"
                  sx={{ marginRight: 2, width: "200px" }}
                >
                  <MenuItem value="" style={{ display: "none" }}>
                    Select Food Item
                  </MenuItem>
                  {foodItem.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.foodItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {selectedFoodItem.length > 0 && selectedFoodItem[0] !== "" ? (
              <Box>
                <Paper sx={{ padding: "8px", width: "200px", marginLeft: "16px" }}>
                  {selectedFoodItem.map((foodItemId) => {
                    const foodItems = foodItem.find((item) => item.id === foodItemId);

                    if (!foodItems) {
                      // If food item data is not available, you can choose to skip rendering or show a placeholder
                      return null; // Skip rendering this item
                    }

                    const allergenList = foodItems.allergens
                      .map((allergenId) => {
                        const allergen = allergens.find((i) => i.id === allergenId);
                        return allergen ? allergen.allergen : null; // Return null if allergen is not found
                      })
                      .filter(Boolean); // Remove null values from the list

                    // Only render the allergens section if there are allergens in the list
                    if (allergenList.length > 0) {
                      return (
                        <Box key={foodItems.id} marginBottom="8px">
                          <Typography variant="subtitle1">{foodItems.foodItem}</Typography>
                          <List dense>
                            {allergenList.map((allergen, index) => (
                              <ListItem key={index}>
                                <ListItemText primary={allergen} />
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      );
                    } else {
                      // Don't render the allergens section if there are no allergens
                      return (
                        <Box key={foodItems.id} marginBottom="8px">
                          <Typography variant="subtitle1">{foodItems.foodItem}</Typography>
                          <Typography variant="body2">No allergens</Typography>
                        </Box>
                      );
                    }
                  })}
                </Paper>
              </Box>
            ) : null}
          </Box>
          <Typography gutterBottom style={{ marginTop: "8px" }}>
            Add Description
          </Typography>
          <TextField
            label="Description"
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            required
            disabled={(useLocationState?.order_count ?? 0) > 0}
            defaultValue={menuDescription}
            onChange={(e) => setMenuDescription(e.target.value)}
          />
          <Button variant="contained" color="primary" fullWidth type="submit">
            {useLocationState ? (useLocationState.order_count > 0 ? "Close" : "Update") : "Add"}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default CreateMenu;
