import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import "./signIn.css";
import apiService from "../../utils/api.mjs";
import { RepeatOneSharp } from "@mui/icons-material";

// TODO remove, this demo shouldn't need to reset the theme.

const SignIn = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(""); // Add this line
  const navigate = useNavigate();

  const handleSignIn = async (event) => {
    event.preventDefault();
    let match = false;

    try {
      if (username.length < 1 || !username.includes("@")) {
        setError("Invalid username or password");
        return;
      } else {
        const response = await apiService.getStoreUserProfile(username);
        if (response.data.length === 0) {
          return;
        } else {
          for (let i = 0; i < response.data.length; i++) {
            if (username === response.data[i].email) {
              sessionStorage.setItem("store", response.data[i].store);
              let school_list = response.data[i].schools.join(",");
              sessionStorage.setItem("associatedSchools", school_list);
              match = true;
              break;
            }
          }
          if (!match) {
            setError("Invalid username or password");
          }
        }
      }
    } catch (error) {
      console.error("Error Signing In:", error);
    }

    try {
      const response = await apiService.signIn(username, password);
      sessionStorage.setItem("authToken", response.data.token);
      sessionStorage.setItem("userId", response.data.user_id);

      navigate("/dashboard");
    } catch (error) {
      console.error("Error Signing In:", error);
    }
  };

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const userId = sessionStorage.getItem("userId");
    const store = sessionStorage.getItem("store");
    const associatedSchools = sessionStorage.getItem("associatedSchools");

    if (authToken && userId && store && associatedSchools) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="custom-box"
      >
        {error && (
          <Typography variant="body1" style={{ color: "red" }}>
            {error}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
          <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" variant="standard" autoFocus onChange={(e) => setUsername(e.target.value)} />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            variant="standard"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;
